<template>
  <div class="page flex-row justify-center">
    <div class="left-view flex-col">
      <span>投放广告方式</span>
      <div :class="tabList.length == 1 ? 'tab' : 'tabs'">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          class="tab-item"
          @mouseenter="mouseenterMode(index)"
          @mouseleave="mouseleaveMode"
          @click="clickMode(index)"
        >
          {{ item }}
        </div>
        <div class="border-left" :style="'height:' + borderLeftHeight"></div>
        <div class="slider" :style="{ top: sliderTop }"></div>
      </div>
    </div>
    <div class="right-view">
      <div v-if="currentTabIndex == 0" class="local flex-col">
        <div class="title-view flex-col">
          <span>使用本地文件视频投放广告</span>
          <div></div>
        </div>
        <div
          class="video-view flex-col"
          v-for="(item, index) in localVideoList"
          :key="index"
        >
          <img :src="item.sort" alt="" />
          <span>{{ item.span1 }}</span>
          <span>{{ item.span2 }}</span>
          <video
            :src="item.videoUrl"
            controls
            class="video"
            style="object-fit: contain"
          ></video>
        </div>
      </div>
      <div v-if="currentTabIndex == 1" class="local flex-col">
        <div class="title-view flex-col">
          <span>使用本地图片，在线合成视频后投放广告</span>
          <div></div>
        </div>
        <div
          class="video-view flex-col"
          v-for="(item, index) in localImageList"
          :key="index"
        >
          <img :src="item.sort" alt="" />
          <span>{{ item.span1 }}</span>
          <span>{{ item.span2 }}</span>
          <video
            :src="item.videoUrl"
            controls
            class="video"
            style="object-fit: contain"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

export default {
  data() {
    return {
      sliderTop: "6rem",
      tabList: ["使用本地视频", "使用本地图片"],
      localVideoList: [
        {
          sort: require("@/assets/img/01.png"),
          span1: "点击“投放广告”，",
          span2:
            "选择“本地视频文件”，从弹窗中选择本地的广告视频文件；",
          videoUrl:
            "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/local/1.mp4",
        },
        {
          sort: require("@/assets/img/02.png"),
          span1: "填写订单投放信息，",
          span2: "提交订单进行支付；",
          videoUrl:
            "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/local/2.mp4",
        },
      ],
      localImageList: [
        {
          sort: require("@/assets/img/01.png"),
          span1: "点击“投放广告”，",
          span2: "选择“本地图片文件”，从弹窗中选择1-3张本地图片文件；",
          videoUrl:
            "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/localImg/1.mov",
        },
        {
          sort: require("@/assets/img/02.png"),
          span1: "填写订单投放信息，",
          span2: "提交订单进行支付；",
          videoUrl:
            "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/localImg/2.mov",
        },
      ],
      currentTabIndex: 0,
    };
  },
  computed: {
    borderLeftHeight() {
      const height = `${(this.tabList.length - 1) * 50 + 16}rem`;
      return height;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取所有视频元素
      const videos = Array.from(document.getElementsByTagName("video"));
      // 为每个视频添加事件监听器
      videos.forEach((video) => {
        video.addEventListener("play", (event) => {
          // 暂停其他所有视频
          videos.forEach((otherVideo) => {
            if (otherVideo !== event.target) {
              otherVideo.pause();
            }
          });
        });
      });
    });
  },
  methods: {
    clickMode(index) {
      if (this.currentTabIndex == index) return;
      this.currentTabIndex = index;
    },
    mouseenterMode(index) {
      this.sliderTop = `${index * 50 + 6}rem`;
    },
    mouseleaveMode() {
      this.sliderTop = `${this.currentTabIndex * 50 + 6}rem`;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 233rem);
  background: #fff;
  .left-view {
    margin: 48rem 83rem 0 0;
    span {
      font-size: 16rem;
      color: #afb0b0;
      line-height: 22rem;
    }
    .tabs,
    .tab {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 28rem;
      position: relative;
      .tab-item {
        font-size: 20rem;
        color: #252927;
        line-height: 28rem;
        padding-left: 14rem;
        margin-top: 22rem;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: #8a8a8a;
        }
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      .border-left {
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        top: 6rem;
        left: 0;
      }
      .slider {
        position: absolute;
        left: -2rem;
        width: 3rem;
        height: 17rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
        transition: all 0.3s ease;
      }
    }
    .tab {
      .tab-item {
        cursor: auto;
        &:hover {
          color: #252927;
        }
      }
    }
  }
  .right-view {
    width: 1060rem;
    background: #f8f8f8;
    border-radius: 6rem;
    margin: 84rem 0 60rem;
    padding: 35rem 75rem 61rem;
    .online,
    .local {
      .title-view {
        position: relative;
        top: 9rem;
        span {
          font-size: 22rem;
          color: #252927;
          line-height: 30rem;
        }
        div {
          width: 108rem;
          height: 3rem;
          background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
          margin-top: 18rem;
        }
      }
      .video-view {
        position: relative;
        margin-top: 80rem;
        img {
          width: 76rem;
          position: absolute;
          top: -24rem;
          left: 0;
        }
        span {
          font-size: 16rem;
          color: #252927;
          line-height: 26rem;
        }
        .video {
          width: 911rem;
          // height: 509rem;
          margin-top: 25rem;
        }
      }
    }
  }
}
</style>
